import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import mitt, { Emitter } from "mitt";

import "./sass/_main.sass";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    emitter: Emitter<{ [key: string]: string }>;
  }
}

const app = createApp(App);
app.config.globalProperties.emitter = mitt();
app.use(store).use(router).mount("#app");
